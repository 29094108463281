<template>
  <div class="row">
    <v-container>
      <v-row align="center" class="row--35">
        <v-col lg="12" md="12" cols="12">
          <div class="about-inner inner">
            <div class="section-title">
              <p class="description" align="center">
                Please be aware that Ubers and taxis are quite difficult to obtain at the end of the evening, due to being approximately 15 minutes out of town and wrapping up at 11pm.  <br /> <br />
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- Start Single Service  -->
    <v-col
      lg="6"
      md="6"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(direction, i) in directions"
      :key="i"
    >
      <div class="error-button btn-center">
        <router-link class="rn-button-style--2 btn_solid btn-center btn-style " :to="direction.route">{{ direction.title }}</router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        directions: [
          {
            title: "To Venue",
            route: `/shuttleTo`,
          },
          {
            title: "From Venue",
            route: "/shuttleFrom",
          }
        ],
      };
    }
  };
</script>

<style lang="scss">
  .super-center {
  text-align: center !important;
  }

  .removeDisplay {
  display: normal !important;
  }

  .btn-center {
  display: block !important;
  margin: 0 auto !important;
  text-align: center !important;
  }

  .btn-style {
  padding-top: 80px !important;
  padding-right: 40px !important;
  padding-bottom: 80px !important;
  padding-left: 40px !important;
  font-size: 32px !important;
  }
</style>
