<template>
  <div>
    <!-- Start Header Area -->
    <HeaderOnePageTwo />
    <!-- End Header Area -->
    <!-- Start Slider Area  -->
    <div class="rn-section-gapDoubleTop bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="section-title text-center mb--20 mb_sm--0">
              <h2 class="heading-title" align="center">Shuttle Bus</h2>
              <div class="about-area">
                <div class="about-wrapper">
                  <ShuttleButtons />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <FooterTwo />
  </div>
</template>

<script>
  import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwo";
  import ShuttleButtons from "../../components/shuttle/ShuttleButtons";
  import FooterTwo from "../../components/footer/FooterTwo";

  export default {
  components: {
  HeaderOnePageTwo,
  FooterTwo,
  ShuttleButtons
  }
  };
</script>

<style lang="scss">

    .feather-menu {
        color: #1d1d24;
    }
</style>
